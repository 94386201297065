import GradebookAssignmentCategory from '../domain/gradebook-assignment-category';
import moment from 'moment';

export default class GradebookAssignmentLinkStatus {

  /**
   * @param selectedCategory {GradebookAssignmentCategory}
   * @param scaledPoints {number|null}
   * @param dueDate {string|null}
   */
  constructor(
    selectedCategory,
    scaledPoints,
    dueDate,
  ) {
    this._selectedCategory = selectedCategory;
    this._scaledPoints = scaledPoints;
    this._dueDate = dueDate ? moment(dueDate) : null;
  }

  get selectedCategory (){
    return this._selectedCategory;
  }

  get scaledPoints () {
    if (!this._scaledPoints) {
      return null;
    } else {
      return this._scaledPoints;
    }
  }

  get dueDate () {
    return this._dueDate;
  }
}
